import _export from "../internals/export";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _isArray from "../internals/is-array";
var $ = _export;
var uncurryThis = _functionUncurryThis;
var isArray = _isArray;
var nativeReverse = uncurryThis([].reverse);
var test = [1, 2];

// `Array.prototype.reverse` method
// https://tc39.es/ecma262/#sec-array.prototype.reverse
// fix for Safari 12.0 bug
// https://bugs.webkit.org/show_bug.cgi?id=188794
$({
  target: "Array",
  proto: true,
  forced: String(test) === String(test.reverse())
}, {
  reverse: function reverse() {
    // eslint-disable-next-line no-self-assign -- dirty hack
    if (isArray(this)) this.length = this.length;
    return nativeReverse(this);
  }
});
export default {};